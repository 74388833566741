<template lang="html">
  <div class="local-authority" v-if="localAuthorityData.subscribers">
    <JayaTitle class="mb-2"
        classType="page-title"
        titleName="Indicateurs du territoire"/>

    <b-card class="border-top-primary border-bottom-primary border-2" sub-title="Abonnés, jardins, quizz & arbres"
        title="Statistiques globales">
      <b-row class="align-items-start justify-content-center" v-if="localAuthorityData.subscribers">
        <b-col
            class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-lg-4 col-xl-3 mb-1">
          <div class="d-flex justify-content-center align-items-center">
            <p class="font-large-2 text-primary font-weight-bolder">{{ localAuthorityData.subscribers.count }}</p>
            <p class="ml-1 font-medium-2 font-weight-bold"> Utilisateurs <br>abonnés </p>
          </div>
          <p class="text-center">A votre collectivité</p>
        </b-col>
        <b-col
            class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-lg-4 col-xl-3 mb-1">
          <div class="d-flex justify-content-center align-items-center">
            <p class="font-large-2 text-primary font-weight-bolder">{{ localAuthorityData.subscribers.active_last_six_month }}</p>
            <p class="ml-1 font-medium-2 font-weight-bold"> Utilisateurs <br> actifs </p>
          </div>
          <p class="text-center">Au cours des 6 derniers mois</p>
        </b-col>
        <b-col
            class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-lg-4 col-xl-3 mb-1">
          <div class="d-flex justify-content-center align-items-center">
            <p class="font-large-2 text-primary font-weight-bolder">{{ localAuthorityData.gardens.total }}</p>
            <p class="ml-1 font-medium-2 font-weight-bold">Jardins <br>crées </p>
          </div>
          <p class="text-center">
            Soit en moyenne
            <b>{{ (localAuthorityData.gardens.total / localAuthorityData.subscribers.count).toFixed(1) }}</b>
            jardins par utilisateur
          </p>
        </b-col>
        <b-col
            class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-lg-4 col-xl-3 mb-1">
          <div class="d-flex justify-content-center align-items-center">
            <p class="font-large-2 text-primary font-weight-bolder">{{ localAuthorityData.biodiv_quiz.count }}</p>
            <p class="ml-1 font-medium-2 font-weight-bold">Réponses <br> au quizz</p>
          </div>
          <p class="text-center">
            Soit en moyenne
            <b>{{ (localAuthorityData.biodiv_quiz.count / localAuthorityData.gardens.total).toFixed(1) }}</b>
            réponses par jardin crée
          </p>
        </b-col>
        <b-col
            class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-lg-4 col-xl-3 mb-1">
          <div class="d-flex justify-content-center align-items-center">
            <p class="font-large-2 text-primary font-weight-bolder">{{ localAuthorityData.gardens.vgardens_total }}</p>
            <p class="ml-1 font-medium-2 font-weight-bold">Planches <br> potagères</p>
          </div>
          <p class="text-center">
            Soit en moyenne
            <b>{{ (localAuthorityData.gardens.vgardens_total / localAuthorityData.gardens.total).toFixed(1) }}</b>
            planches par jardin crée
          </p>
        </b-col>
        <b-col
            class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-lg-4 col-xl-3 mb-1">
          <div class="d-flex justify-content-center align-items-center">
            <p class="font-large-2 text-primary font-weight-bolder">{{ localAuthorityData.gardens.balcony }}</p>
            <p class="ml-1 font-medium-2 font-weight-bold">Jardins <br> sur balcon</p>
          </div>
          <p class="text-center">
            Soit en moyenne
            <b>{{ (100 * localAuthorityData.gardens.balcony / localAuthorityData.gardens.total).toFixed(0) }}%</b>
            des jardins crées
          </p>
        </b-col>
        <b-col
            class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-lg-4 col-xl-3 mb-1">
          <div class="d-flex justify-content-center align-items-center">
            <p class="font-large-2 text-primary font-weight-bolder">{{ localAuthorityData.gardens.trees }}</p>
            <p class="ml-1 font-medium-2 font-weight-bold">Arbres <br> déclarés</p>
          </div>
          <p class="text-center">
            Soit en moyenne
            <b>{{ (localAuthorityData.gardens.trees / localAuthorityData.gardens.total).toFixed(1) }}</b>
            arbres par jardin crée
          </p>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="justify-content-center border-top-dark border-bottom-dark border-2" sub-title="Locales et envahissantes"
        title="Suivis des espèces">
      <b-row class="justify-content-center">
        <b-col class="col-12 col-lg-6 ">
          <b-card-title class="text-success text-center d-flex align-items-center justify-content-center">
            <img alt="Icône plante locale" src="@/assets/images/icons/other/local.svg" style="height:3rem">
            <span class="ml-1">Espèces indigènes</span>
          </b-card-title>
          <b-row class="justify-content-center align-items-start">
            <b-col
                class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-xl-4 mb-1">
              <div class="d-flex justify-content-center align-items-center">
                <p class="font-large-2 text-success font-weight-bolder">{{
                    localAuthorityData.native_plant.distinct
                  }}</p>
                <p class="ml-1 font-medium-2 font-weight-bold">Espèces <br> différentes</p>
              </div>
              <p class="text-center">
                Dans les jardins de vos abonnés.
              </p>
            </b-col>
            <b-col
                class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-xl-4 mb-1">
              <div class="d-flex justify-content-center align-items-center">
                <p class="font-large-2 text-success font-weight-bolder">{{ localAuthorityData.native_plant.total }}</p>
                <p class="ml-1 font-medium-2 font-weight-bold">Plantes <br> indigènes</p>
              </div>
              <p class="text-center">
                Soit en moyenne
                <b>{{
                    (localAuthorityData.native_plant.total / localAuthorityData.gardens.total).toFixed(0)
                  }}</b>
                par jardin.
              </p>
            </b-col>
            <b-col
                class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-xl-4 mb-1">
              <div class="d-flex justify-content-center align-items-center">
                <p class="font-large-2 text-success font-weight-bolder">
                  {{
                    (100 * localAuthorityData.native_plant.nb_garden / localAuthorityData.gardens.total).toFixed(0)
                  }}%</p>
                <p class="ml-1 font-medium-2 font-weight-bold">Part des <br> jardins</p>
              </div>
              <p class="text-center">
                Avec au moins une plante d'une espèce indigène.
              </p>
            </b-col>
            <b-col
                class="d-flex flex-column justify-content-center align-items-center col-12  col-md-11">
              <b-table striped hover :items="localAuthorityData.native_plant.details"
                       :fields="fields_native_plant" per-page="5"></b-table>
            </b-col>
          </b-row>
        </b-col>
        <jaya-divider class="col-12 d-lg-none"></jaya-divider>
        <b-col class="col-12 col-lg-6">
          <b-card-title class="text-danger text-center d-flex align-items-center justify-content-center">
            <img alt="Icône plante EEE" class="" src="@/assets/images/icons/other/invasive.svg" style="height:3rem">
            <span class="ml-1">Espèces exotiques envahissantes</span>
          </b-card-title>
          <b-row class="justify-content-center align-items-start">
            <b-col
                class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-xl-4 mb-1">
              <div class="d-flex justify-content-center align-items-center">
                <p class="font-large-2 text-danger font-weight-bolder">{{
                    localAuthorityData.invasive_plant.distinct
                  }}</p>
                <p class="ml-1 font-medium-2 font-weight-bold">Espèces <br> différentes</p>
              </div>
              <p class="text-center">
                Dans les jardins de vos abonnés.
              </p>
            </b-col>
            <b-col
                class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-xl-4 mb-1">
              <div class="d-flex justify-content-center align-items-center">
                <p class="font-large-2 text-danger font-weight-bolder">{{ localAuthorityData.invasive_plant.total }}</p>
                <p class="ml-1 font-medium-2 font-weight-bold">Plantes <br> invasives</p>
              </div>
              <p class="text-center">
                Soit en moyenne
                <b>{{
                    (localAuthorityData.invasive_plant.total / localAuthorityData.gardens.total).toFixed(0)
                  }}</b>
                par jardin.
              </p>
            </b-col>
            <b-col
                class="d-flex flex-column justify-content-center align-items-center col-12 col-md-6 col-xl-4 mb-1">
              <div class="d-flex justify-content-center align-items-center">
                <p class="font-large-2 text-danger font-weight-bolder">
                  {{
                    (100 * localAuthorityData.invasive_plant.nb_garden / localAuthorityData.gardens.total).toFixed(0)
                  }}%</p>
                <p class="ml-1 font-medium-2 font-weight-bold">Part des <br> jardins</p>
              </div>
              <p class="text-center">
                Avec au moins une espèce exotique envahissante.
              </p>
            </b-col>
            <b-col
                class="d-flex flex-column justify-content-center align-items-center col-12 col-md-11">
              <b-table striped hover :items="localAuthorityData.invasive_plant.details"
                       :fields="fields_invasive_plant" per-page="5"></b-table>
            </b-col>
            <b-col class="d-flex flex-column justify-content-center align-items-center col-12 mt-2">
              <b-form-checkbox v-model="compareIndividualPlants" button button-variant="warning"
                  variant="success">
                Suivi détaillé des espèces E.E.E
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-if="compareIndividualPlants"
            class="col-12 flex-column justify-content-center align-items-center mt-3">
          <h3 class="text-warning text-center font-medium-4">Suivi des espèces exotiques envahissantes</h3>
          <p class="text-center">Nombre d'espèces / variétés à suivre</p>
          <div class="d-flex justify-content-center EEE">
            <b-form-spinbutton v-model="comparedPlants" class="col-6 col-md-3" max="9" min="0" size="sm"/>
          </div>

          <b-row class="mt-1">
            <b-col v-for="plantNumber in Number(comparedPlants)" :key="plantNumber"
                class="col-12 col-md-6 col-lg-4">
              <b-card v-if="selectedPlant[plantNumber-1]"
                  border-variant="light">
                <b-card-title class="d-flex justify-content-start align-items-center mb-0">
                  <span>{{ selectedPlant[plantNumber - 1].common_name }}</span>
                  <b-button
                      v-if="selectedPlant[plantNumber - 1].common_name !== ''"
                      class="btn-icon"
                      variant="flat-warning"
                      @click="changePlant(plantNumber-1, false)">
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                  <b-button
                      v-else
                      variant="warning"
                      @click="changePlant(plantNumber-1, false)">
                    Choisir une plante E.E.E
                  </b-button>
                </b-card-title>

                <b-card-sub-title v-if="selectedPlant[plantNumber-1].common_name !== ''"
                    class="text-justify mt-25">
                  {{ selectedPlant[plantNumber - 1].common_name }} est présent dans
                  <strong class="text-xl">{{ selectedPlant[plantNumber - 1].garden_count }}</strong>
                  jardins de votre territoire. C'est à dire
                  <strong
                      class="text-xl">{{
                      Math.round(100 * selectedPlant[plantNumber - 1].garden_count / localAuthorityData.gardens.total)
                    }}%</strong>
                  des abonnées.
                </b-card-sub-title>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="justify-content-center  border-top-danger border-bottom-danger border-2" title="Observations de la faune" sub-title="Dans les jardins du territoire">
      <vue-simple-suggest
        v-model="faunaCities"
        :debounce="200"
        :list="citiesSearch"
        class="mb-1 w-100"
        value-attribute="id"
        display-attribute="name"
        @select="fetchObservations"
      />
      <div class="overflow-auto">
        <b-table
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            small
        ></b-table>

        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
        ></b-pagination>
      </div>
    </b-card>
    <b-card v-if="localAuthorityData" class="border-top-secondary border-bottom-secondary border-2" sub-title="Statistique des réponses aux quiz"
        title="Indicateur de biodiversité">
      <b-row v-if="localAuthorityData.biodiv_quiz.count > 0" class="justify-content-center align-items-start">
        <b-col class="col-12 col-lg-4 d-flex flex-column justify-content-center mb-4">
          <h3 class="text-primary text-center font-medium-4">Score moyen</h3>
          <p class="text-center">calculé sur {{ localAuthorityData.biodiv_quiz.count }} réponses</p>
          <vue-apex-charts :options="averageBiodiversityScoreRadialBar.chartOptions"
              :series="scoreToSeries(localAuthorityData.biodiv_quiz.score.avg)" class="mt-1" height='250'
              type="radialBar"/>
        </b-col>
        <b-col class="col-12 col-lg-4 d-flex flex-column justify-content-center mb-4">
          <h3 class="text-primary text-center font-medium-4">Répartition des scores</h3>
          <vue-apex-charts :options="histogramBiodiversityScoreBar.chartOptions"
                           :series="histogramBiodiversityScoreBar.series" class="mt-1" height='250'
                           type="line"/>
        </b-col>
        <b-col class="col-12 d-flex flex-column justify-content-center mb-4">
          <h3 class="text-primary text-center font-medium-4">Contenu du jardin</h3>
          <p class="text-center">Types de végétation et aménagements</p>
          <b-row class="mt-1">
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Espace pavé ou gravilloné</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.paving / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.paving/localAuthorityData.biodiv_quiz.count)" style="height:8px"
                  variant="danger"/>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Mobilier de jardin</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.furniture / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.furniture/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="secondary"/>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Arbre</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.tree / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.tree/localAuthorityData.biodiv_quiz.count)" style="height:8px"
                  variant="success"/>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Arbuste</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.shrub / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.shrub/localAuthorityData.biodiv_quiz.count)" style="height:8px"
                  variant="success"/>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Plante en pot</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.potplant / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.potplant/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="success"/>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Potager</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.vgarden / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.vgarden/localAuthorityData.biodiv_quiz.count)" style="height:8px"
                  variant="success"/>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Plante potagère</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.vegetable / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.vegetable/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="success"/>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Plante grimpante</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.climbing / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.climbing/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="success"/>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Massif de fleurs en pleine terre</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.flowerbed / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.flowerbed/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="success"/>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Un endroit laissé en friche</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.wasteland / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.wasteland/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="success"/>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Pelouse</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.grass / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.grass/localAuthorityData.biodiv_quiz.count)" style="height:8px"
                  variant="success"/>
              <p class="mt-25 mb-0"> Réparti comme suit:</p>
              <p class="m-0"> - jamais tondue
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.grassnever / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <p class="m-0"> - 1 ou 2 fois par an
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.grass1or2 / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <p class="m-0"> - plus de 2 fois par an
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.grassmore2 / localAuthorityData.biodiv_quiz.count) }} %)</p>
            </b-col>
            <b-col class="col-12 col-md-6 mb-1">
              <p class="mb-25"><strong>Haie</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.hedge / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.hedge/localAuthorityData.biodiv_quiz.count)" style="height:8px"
                  variant="success"/>
              <p class="mt-25 mb-0"> Réparti comme suit:</p>
              <p class="m-0"> - avec 1 espèce
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.hedge1specy / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <p class="m-0"> - avec 2 espèces ou plus
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.hedge2species / localAuthorityData.biodiv_quiz.count) }}
                %)</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-12 col-lg-6 d-flex flex-column justify-content-center mb-4">
          <h3 class="text-primary text-center font-medium-4">Surface végétalisée</h3>
          <p class="text-center">Répartition</p>
          <div class="d-flex justify-content-center">
            <vue-apex-charts v-if="dataLoaded" :options="pieChartGreenArea.chartOptions" :series="pieChartGreenArea.series"
                type="donut" width="100%"/>
          </div>
        </b-col>
        <b-col class="col-12 col-lg-6 d-flex flex-column justify-content-center mb-4">
          <h3 class="text-primary text-center font-medium-4">Produits utilisés</h3>
          <p class="text-center">Biocides et engrais</p>
          <b-row class="mt-1">
            <b-col class="col-12 mb-1">
              <p class="mb-25"><strong>Herbicides</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.herbicides / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.herbicides/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="danger"/>
            </b-col>
            <b-col class="col-12 mb-1">
              <p class="mb-25"><strong>Insecticides</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.insecticides / localAuthorityData.biodiv_quiz.count) }} %)
              </p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.insecticides/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="danger"/>
            </b-col>
            <b-col class="col-12 mb-1">
              <p class="mb-25"><strong>Pesticides</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.pesticides / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.pesticides/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="danger"/>
            </b-col>
            <b-col class="col-12 mb-1">
              <p class="mb-25"><strong>Terreau</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.pottingsoil / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.pottingsoil/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="success"/>
            </b-col>
            <b-col class="col-12 mb-1">
              <p class="mb-25"><strong>Engrais "fait maison"</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.homemadefertilizer / localAuthorityData.biodiv_quiz.count) }}
                %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.homemadefertilizer/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="secondary"/>
            </b-col>
            <b-col class="col-12 mb-1">
              <p class="mb-25"><strong>Engrais biologique</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.biofertilizer / localAuthorityData.biodiv_quiz.count) }} %)
              </p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.biofertilizer/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="secondary"/>
            </b-col>
            <b-col class="col-12 mb-1">
              <p class="mb-25"><strong>Engrais chimiques</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.chemicals / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.chemicals/localAuthorityData.biodiv_quiz.count)"
                  style="height:8px"
                  variant="secondary"/>
            </b-col>
            <b-col class="col-12 mb-1">
              <p class="mb-25"><strong>Rien</strong>
                ({{ Math.round(100 * localAuthorityData.biodiv_quiz.details.nothing / localAuthorityData.biodiv_quiz.count) }} %)</p>
              <b-progress :value="Math.round(100*localAuthorityData.biodiv_quiz.details.nothing/localAuthorityData.biodiv_quiz.count)" style="height:8px"
                  variant="success"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="localAuthorityData" class="border-top-info border-bottom-info border-2"
        sub-title="Statistique des potagers et récoltes" title="Potagers">
      <b-row class="mt-1 justify-content-center">
        <b-col class="col-12 col-lg-4 flex-grow-1 mb-2">
          <h3 class="text-primary text-center font-medium-4">Infos générales</h3>
          <p class="text-center">Entretien des jardins</p>
          <b-row class="col-12 d-flex flex-wrap justify-center items-stretch flex-grow mt-8">
            <b-col class="p-2 col-6">
              <p class="text-center font-large-1 text-primary font-weight-bolder">
                {{ localAuthorityData.gardens.vgardens_distinct }}<br></p>
              <p class="text-center mb-1 font-weight-bold"> Jardins<br> avec potager </p>
            </b-col>
            <b-col class="p-2 col-6">
              <p class="text-center font-large-1 text-primary font-weight-bolder">{{
                  localAuthorityData.gardens.vgardens_total
                }}<br></p>
              <p class="text-center mb-1 font-weight-bold"> Planches <br> potagères </p>
            </b-col>
            <b-col class="p-2 col-6">
              <p class="text-center font-large-1 text-primary font-weight-bolder">
                {{localAuthorityData.mulching.total}}<br>
              </p>
              <p class="text-center mb-1 font-weight-bold"> Paillages <br> réalisés</p>
            </b-col>
            <b-col class="p-2 col-6">
              <p class="text-center font-large-1 text-primary font-weight-bolder">
                {{ localAuthorityData.fertilization.total }}<br>
              </p>
              <p class="text-center mb-1 font-weight-bold"> Fertilisations <br> réalisées</p>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="localAuthorityData.mulching"
            class="col-12 col-lg-4 flex-grow-1 mb-2">
          <h3 class="text-primary text-center font-medium-4">Paillages</h3>
          <p class="text-center">Répartition des types</p>
          <vue-apex-charts v-if="dataLoaded" :options="pieChartMulching.chartOptions" :series="pieChartMulching.series"
              type="donut" width="100%"/>
        </b-col>

        <b-col v-if="localAuthorityData.fertilization"
            class="col-12 col-lg-4 flex-grow-1 mb-2">
          <h3 class="text-primary text-center font-medium-4">Fertilisations</h3>
          <p class="text-center">Répartition des types</p>
          <vue-apex-charts v-if="dataLoaded" :options="pieChartFertilization.chartOptions" :series="pieChartFertilization.series"
              type="donut" width="100%"/>
        </b-col>
      </b-row>

      <div class="flex-column justify-content-center align-items-center mt-3">
        <h3 class="text-primary text-center font-medium-4">Suivi des récoltes</h3>
        <p class="text-center">Nombre d'espèces / variétés à suivre</p>
        <div class="d-flex justify-content-center">
          <b-form-spinbutton v-model="comparedPlantsHarvest" class="col-6 col-md-3" max="9" min="0" size="sm"/>
        </div>

        <b-row class="mt-1">
          <b-col v-for="plantNumber in Number(comparedPlantsHarvest)" :key="plantNumber"
              class="col-12 col-md-6 col-lg-4">
            <b-card v-if="selectedPlantHarvest[plantNumber-1]"
                border-variant="light">
              <b-card-title class="d-flex justify-content-start align-items-center mb-0">
                <span>{{ selectedPlantHarvest[plantNumber - 1].common_name }}</span>
                <b-button
                    v-if="selectedPlantHarvest[plantNumber - 1].common_name !== ''"
                    class="btn-icon"
                    variant="flat-primary"
                    @click="changePlant(plantNumber-1, true)">
                  <feather-icon icon="EditIcon"/>
                </b-button>
                <b-button
                    v-else
                    variant="primary"
                    @click="changePlant(plantNumber-1, true)">
                  Choisir une plante
                </b-button>
              </b-card-title>

              <b-card-sub-title v-if="selectedPlantHarvest[plantNumber-1].common_name !== ''"
                  class="text-justify mt-25">
                Au moins {{
                  selectedPlantHarvest[plantNumber - 1].total_mass ? selectedPlantHarvest[plantNumber - 1].total_mass : 0
                }} kg récoltés sur l'année écoulée. <br>
                Soit {{
                  selectedPlantHarvest[plantNumber - 1].total_mass ? selectedPlantHarvest[plantNumber - 1].total_mass / localAuthorityData.gardens.vgardens_distinct : 0
                }}
                kg en moyenne par jardin ayant un potager.
              </b-card-sub-title>
            </b-card>
          </b-col>
        </b-row>

        <vue-apex-charts v-if="showBar" :options="optionsBarChart" :series="series" height="350" type="bar"
            width="100%"/>
      </div>
    </b-card>

    <b-modal ref="popUpPlantChoice"
        v-model="popUpPlantChoice"
        hide-footer
        title="Choisir une plante E.E.E">

      <!-- TODO: Modify to search for only invasive plants -->
      <PlantSearch :activeFilter="false" :localAuth="$store.state.localAuthority.memberOf.id" :preloadContent="false" :selectedPlantId="selectedPlant.id"
          class="mb-1" listType="simple" size="500px"
          v-on:newSelection="newPlantSelect"></PlantSearch>
    </b-modal>

    <b-modal ref="popUpPlantChoiceHarvest"
        v-model="popUpPlantChoiceHarvest"
        hide-footer
        title="Choisir une plante">

      <PlantSearch :activeFilter="false" :preloadContent="false" :selectedPlantId="selectedPlantHarvest.id"
          :vGPlantOnly="true" class="mb-1" listType="simple"
          v-on:newSelection="newPlantHarvestSelect"></PlantSearch>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import JayaTitle from '@/components/global/JayaTitle.vue'
import PlantSearch from '@/components/plants/PlantSearch.vue'
import VueApexCharts from 'vue-apexcharts'
import VueSimpleSuggest from 'vue-simple-suggest'

import {
  BButton,
  BCard,
  BCardSubTitle,
  BCardTitle,
  BCol,
  BFormCheckbox,
  BFormSpinbutton,
  BModal,
  BProgress,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    JayaTitle,
    PlantSearch,
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BProgress,
    BPagination,
    BFormSpinbutton,
    BButton,
    BModal,
    BFormCheckbox,
    BTable,
    VueSimpleSuggest
  },
  data() {
    return {
      faunaCities: {id: -1, name: ""},
      localAuthorityData: {},
      compareIndividualPlants: false,
      selectedPlant: [{common_name: ''}],
      selectedPlantHarvest: [{common_name: ''}],
      indexUpdating: null,
      indexUpdatingHarvest: null,
      popUpPlantChoice: false,
      popUpPlantChoiceHarvest: false,
      comparedPlants: 0,
      comparedPlantsHarvest: 0,
      dataLoaded: false,
      averageBiodiversityScoreRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '70%',
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: 14,
                  color: '#99a2ac',
                  fontSize: '3rem'
                }
              }
            }
          },
          colors: ['var(--primary)'],
          stroke: {
            lineCap: 'round'
          }
        }
      },
      histogramBiodiversityScoreBar: {
        series: [
          {
            name: 'Nombre de réponse',
            data: [],
          }
        ],
        chartOptions: {
          chart: {
            type: 'line',
            height: 350
          },
          stroke: {
            curve: 'stepline',
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            hover: {
              sizeOffset: 4
            }
          },
          xaxis: {
            categories: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90],
          },
          yaxis: {
            title: {
              text: 'Nombre de réponses'
            }
          },
          colors:  [`var(--primary)`],
        },
      },
      pieChartMulching: {
        series: [],
        chartOptions: {
          labels: [],
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            pie: {
              donut: {
                size: '45%'
              }
            }
          }
        }
      },
      pieChartFertilization: {
        series: [],
        chartOptions: {
          labels: [],
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            pie: {
              donut: {
                size: '45%'
              }
            }
          }
        }
      },
      pieChartGreenArea: {
        series: [],
        chartOptions: {
          labels: ['Supérieure à 75%', 'Entre 50 et 75%', 'Entre 25 et 49%', 'Inférieure à 25%'],
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            pie: {
              donut: {
                size: '45%'
              }
            }
          }
        }
      },
      detailsMulch: false,
      mulchTypes: {
        "straw": "Paille",
        "brf": "BRF",
        "mowing": "Herbe de tonte",
        "green wastes": "Déchets verts",
        "wood debris": "Débris de bois",
        "hay": "Foin",
        "dead leafs": "Feuilles mortes",
        "cardboard": "Carton",
        "tarpaulin": "Bâche"
      },
      fertilizationTypes: {
        "vegetable manure": "Purin",
        "animal manure": "Fumier",
        "compost": "Compost",
        "green fertilizer": "Engrais vert",
        "mineral": "Engrais mineral",
        "artificial fertilizer": "Engrais artificiel",
        "other": "Autre",
      },
      optionsBarChart: {
        colors: [],
        chart: {
          id: "Récoltes sur l'année écoulée (en kg)"
        },
        xaxis: {
          categories: []
        },
        color: "#04B587"
      },
      series: [{
        name: 'Masse récoltée (kg)',
        data: []
      }],
      showBar: false,
      fields_native_plant: [
        {
          key: 'scientific_name',
          sortable: true,
          label:'Nom scientifique',
          thClass: "text-success",
        },
        {
          key: 'garden_count',
          sortable: true,
          label:'Jardins',
          thClass: "text-success",
        },
        {
          key: 'instances__count',
          sortable: true,
          label:'Plants total',
          thClass: "text-success",
        },
      ],
      fields_invasive_plant: [
        {
          key: 'scientific_name',
          sortable: true,
          label:'Nom scientifique',
          thClass: "text-danger",
        },
        {
          key: 'garden_count',
          sortable: true,
          label:'Jardins',
          thClass: "text-danger",
        },
        {
          key: 'instances__count',
          sortable: true,
          label:'Plants total',
          thClass: "text-danger",
        },
      ],
      perPage: 10,
      currentPage: 1,
      rows:9,
      items: [],
    }
  },
  mounted() {
    this.$store.dispatch("localAuthority/getStats", this.$store.state.localAuthority.memberOf.id)
        .then(response => {
          this.localAuthorityData = response.data[0]

          this.pieChartMulching.series = Object.values(response.data[0].mulching)
          this.pieChartMulching.chartOptions.labels = Object.keys(response.data[0].mulching)

          this.pieChartFertilization.series = Object.values(response.data[0].fertilization)
          this.pieChartFertilization.chartOptions.labels = Object.keys(response.data[0].fertilization)

          this.localAuthorityData.mulching.total = this.pieChartMulching.series.reduce((a, b) => a + b, 0)
          this.localAuthorityData.fertilization.total = this.pieChartFertilization.series.reduce((a, b) => a + b, 0)

          this.histogramBiodiversityScoreBar.series[0].data = Object.values(response.data[0].biodiv_quiz.score.hist)

          let colorsMeaning = ["var(--success)", "var(--dark)", "var(--warning)", "var(--danger)"]
          let colorsNoMeaning = ["var(--primary)", "var(--dark)", "var(--secondary)", "var(--info)",
            "var(--primary-light)", "var(--light)", "var(--secondary-light)", "var(--info-light)",]

          this.pieChartMulching.chartOptions.colors = colorsNoMeaning
          this.pieChartFertilization.chartOptions.colors = colorsNoMeaning
          this.pieChartGreenArea.chartOptions.colors = colorsMeaning
          this.optionsBarChart.colors = colorsNoMeaning

          this.pieChartGreenArea.series = [this.localAuthorityData.biodiv_quiz.details.areasup75,
            this.localAuthorityData.biodiv_quiz.details.areabtw5075,
            this.localAuthorityData.biodiv_quiz.details.areabtw2550,
            this.localAuthorityData.biodiv_quiz.details.areainf25]

          this.dataLoaded = true
        })
        .catch(() => {
          this.localAuthorityData = {}
          this.$notify({
            "title": "Erreur",
            "text": "Une erreur est survenue lors de la récupération des statistiques de votre collectivité, veuillez réessayer ultérieurement",
            "color": "danger",
            "time": 4000
          })
        })
    this.$store.dispatch("localAuthority/getObservationsStats", {
      id : this.$store.state.localAuthority.memberOf.id,
      page: this.currentPage - 1,
      limit: this.perPage
    })
        .then(response => {
          this.rows = response.data.count
          this.items = Array.from({ length: response.data.count }).map(() => {
            return {scientific_name: "", common_name: "", nb_observations: 0}
          })
          for (let i=0; i<response.data.results.length; i++) {
            this.items[i] = response.data.results[i]
          }
        })
  },
  methods: {
    fetchObservations(city) {
      this.$store.dispatch("localAuthority/getObservationsStats", {
        id : this.$store.state.localAuthority.memberOf.id,
        page: this.currentPage - 1,
        limit: this.perPage,
        city: city.id
      })
          .then(response => {
            this.rows = response.data.count
            this.items = Array.from({ length: response.data.count }).map(() => {
              return {scientific_name: "", common_name: "", nb_observations: 0}
            })
            for (let i=0; i<response.data.results.length; i++) {
              this.items[i] = response.data.results[i]
            }
          })
    },
    citiesSearch(searchText) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("localAuthority/searchPossibleCities", {name: searchText, localAuthId: this.$store.state.localAuthority.memberOf.id})
            .then(results => {
              //make nam + zipcode attribute
              resolve(results.data.results.map(obj => ({...obj, display: obj.zip_code + ' - ' + obj.name})))
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    changePlant(index, harvest = false) {

      if (harvest) {
        this.popUpPlantChoiceHarvest = true
        this.indexUpdatingHarvest = index
      } else {
        this.popUpPlantChoice = true
        this.indexUpdating = index
      }
    },
    newPlantSelect(plant) {
      this.$store.dispatch("localAuthority/detailsInvasive", {
        id: this.$store.state.localAuthority.memberOf.id,
        plantId: plant.id
      })
          .then(response => {
            this.selectedPlant[this.indexUpdating] = response.data
            this.popUpPlantChoice = false
          })
    },
    newPlantHarvestSelect(plant) {
      this.showBar = false
      this.$store.dispatch("localAuthority/harvestInfos", {
        id: this.$store.state.localAuthority.memberOf.id,
        plant_id: plant.id
      })
          .then(response => {
            this.selectedPlantHarvest[this.indexUpdatingHarvest] = response.data[0]
            this.popUpPlantChoiceHarvest = false
            let categories = []
            let data = []
            for (let plant of this.selectedPlantHarvest.filter(e => e.common_name !== '')) {
              data.push(plant.total_mass)
              categories.push(plant.common_name)
            }
            this.series[0].data = data
            this.optionsBarChart.xaxis.categories = categories
            setTimeout(this.showBar = true, 1000)
          })
    },
    scoreToSeries(score) {
      score = score * 100
      score = score.toPrecision(2)
      return [score]
    }
  },
  watch: {
    comparedPlants(value, oldValue) {
      if (value > oldValue) {
        while (value > oldValue) {
          this.selectedPlant.push({common_name: ''})
          oldValue++
        }
      } else {
        while (oldValue > value) {
          this.selectedPlant.pop()
          value++
        }
      }
    },
    comparedPlantsHarvest(value, oldValue) {
      if (value > oldValue) {
        while (value > oldValue) {
          this.selectedPlantHarvest.push({common_name: ''})
          oldValue++
        }
      } else {
        while (oldValue > value) {
          this.selectedPlantHarvest.pop()
          this.showBar = false
          this.optionsBarChart.xaxis.categories.pop()
          this.series[0].data.pop()
          this.showBar = true
          value++
        }
      }
    },
    currentPage(value) {
      this.$store.dispatch("localAuthority/getObservationsStats", {
        id : this.$store.state.localAuthority.memberOf.id,
        page: this.perPage * (value - 1),
        limit: this.perPage
      }).then((response) => {
        for (let i=0; i<response.data.results.length; i++) {
          this.items[this.perPage * (value - 1) + i].nb_observations = response.data.results[i].nb_observations
          this.items[this.perPage * (value - 1) + i].common_name = response.data.results[i].common_name
          this.items[this.perPage * (value - 1) + i].scientific_name = response.data.results[i].scientific_name
        }
      })
    }
  }
}
</script>

<style lang="scss">
.router-view {
  padding-bottom: 0 !important;
}

.apexcharts-bar-area {
  fill: rgba(var(--vs-primary), 0.85);
}

.EEE > .form-control:focus {
  border-color: var(--warning) !important;
}
</style>
